import React from 'react';
import { Link } from 'gatsby';

const posts = [
  {
    title: 'P&B Houtbouw',
    href: '/voor-wie/houtbouw',
    category: { name: 'HOUTBOUW' },
    description:
      'P&B Houtbouw werkt nu ruim een jaar met Homigo en vind vooral het delen van bestanden en de chatfunctie een groot pluspunt.',
    imageUrl:
      'https://images.unsplash.com/photo-1544164560-adac3045edb2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1311&q=80',
  },
  {
    title: 'Projectontwikkelaar Nieuwhof',
    href: '/voor-wie/projectontwikkelaar',
    category: { name: 'PROJECTONTWIKKELAAR' },
    description:
      'Projectontwikkelaar Nieuwhof groeide de laatste jaren ontzettend hard. Fijn natuurlijk, maar soms ook lastig.',
    imageUrl:
      'https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2940&q=80',
  },
  {
    title: 'Renovatiebedrijf Joossens',
    href: '/voor-wie/renovatiebedrijf',
    category: { name: 'RENOVATIEBEDRIJF' },
    description:
      'Bouwbedrijf Joossens was al een tijdje op zoek naar een app die hen kon helpen. “We werkten ontzettend omslachtig met allerlei lijstjes en programma’s.',
    imageUrl:
      'https://images.unsplash.com/photo-1517581177682-a085bb7ffb15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2944&q=80',
  },
  {
    title: 'Bouwbedrijf Novus',
    href: '/voor-wie/bouwbedrijf',
    category: { name: 'BOUWBEDRIJF' },
    description:
      'Ko Schouten heeft sinds 4 jaar een bouwbedrjf. We doen alles wat met de bouw te maken heeft. ',
    imageUrl:
      'https://images.unsplash.com/photo-1616320999187-3c004dad4f0b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80',
  },
];

export default function VoorwieItem() {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-5xl mx-auto">
        <div className="mt-12 max-w-lg mx-auto grid gap-8 lg:grid-cols-1 lg:max-w-none  hidden md:block">
          {posts.map((post) => (
            <div key={post.title} className="mb-8 flex rounded-lg shadow-lg overflow-hidden ">
              <div className="flex-shrink-0">
                <Link to={post.href} className="block mt-2">
                  <img className="w-96 h-64 object-cover" src={post.imageUrl} alt="" />
                </Link>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-md font-medium text-primary">{post.category.name}</p>
                  <Link to={post.href} className="block mt-1">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </Link>
                </div>
                <div className="mt-2 flex items-center">
                  <Link
                    to={post.href}
                    className="mt-8 bg-primary border border-transparent rounded-md px-5 py-3 inline-flex items-center text-base font-medium text-white hover:bg-yellow-500"
                  >
                    Lees meer
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="md:hidden mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-md font-medium text-primary">{post.category.name}</p>
                  <Link to={post.href} className="block mt-1">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </Link>
                </div>
                <div className="mt-2 flex items-center">
                  <Link
                    to={post.href}
                    className="mt-8 bg-primary border border-transparent rounded-md px-5 py-3 inline-flex items-center text-base font-medium text-white hover:bg-yellow-500"
                  >
                    Lees meer
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
