import React from 'react';
import Layout from '../components/layout';
import VoorwieItem from '../components/VoorwieItem';
import VoorWieIcons from '../components/VoorWieIcons';
import { SEO } from '../components/SEO/SEO';

const voorwiePage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Voor wie' }} />
    <VoorWieIcons />
    <VoorwieItem />
  </Layout>
);

export default voorwiePage;
