import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
/* This example requires Tailwind CSS v2.0+ */

export default function VoorWieIcons() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
        <div className="text-center justify-items-center grid grid-cols-2 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
          <div>
            <StaticImage src="../img/voorwie/schilder.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Schilders</h3>
          </div>
          <div>
            <StaticImage src="../img/voorwie/stukadoors.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Stukadoors</h3>
          </div>
          <div>
            <StaticImage src="../img/voorwie/loodgieter.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Loodgieters</h3>
          </div>
          <div>
            <StaticImage src="../img/voorwie/timmerman.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Timmermannen</h3>
          </div>
          <div>
            <StaticImage src="../img/voorwie/aannemer.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Aannemers</h3>
          </div>
          <div>
            <StaticImage src="../img/voorwie/developer.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Projectontwikkelaars</h3>
          </div>
          <div>
            <StaticImage src="../img/voorwie/bouw.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Bouwbegeleiders</h3>
          </div>
          <div>
            <StaticImage src="../img/voorwie/metalen.svg" alt="" className="h-24 w-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Metselbedrijven</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
